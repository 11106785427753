<template>
  <v-card flat color="transparent">
    <v-app-bar app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="62" width="100%">
      <v-layout row wrap align-center class="my-0 mx-n1">
        <v-flex :xs3="moduleObj.enableprofile" :xs1="!moduleObj.enableprofile">
          <v-btn class="mr-2" icon dark small @click="$router.go(-1)">
            <v-icon size="20"> mdi-arrow-left </v-icon>
          </v-btn>
          <v-avatar :color="(!moduleObj._id || moduleObj.profileimage) ? '' : 'orange'" size="45" :key="reInit" class="mt-1" v-if="moduleObj.enableprofile">
            <img v-if="moduleObj.enableprofile && (moduleObj._id ? moduleObj.profileimage : true)" :src="moduleObj.profileimage || require('/src/assets/profile.jpg')" alt="User">
            <span v-else class="title white--text"> {{ fields.name ? fields.name.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase() : '' }} </span>
          </v-avatar>
          <v-menu offset-y v-if="moduleObj.enableprofile">
            <template #activator="{ on, attrs }">
              <v-icon aria-hidden="true" dark size="17" v-bind="attrs" v-on="on" style="position: absolute; bottom: 5px; left: 80px;" @click="showProfileMenu = true"> mdi-camera </v-icon>
            </template>
            <v-card class="pb-2 pt-0">
              <template v-for="(item, index) in profileMenuList">
                <v-list-item :key="index" @click="item.click()" class="py-2 my-0 pl-3 pr-4 custom-vlist-item mb-n2" v-if="item.ischange ? true : !!moduleObj.profileimage">
                  <v-list-item-action v-if="item.icon" class="my-1 mr-2">
                    <v-icon size="20"> {{ item.icon }} </v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="py-0">
                    <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-card>
          </v-menu>
          <v-file-input accept="image/png, image/jpeg, image/bmp" style="display: none" ref="file" v-model="moduleObj.profile" @change="uploadProfile($event, 'New')"></v-file-input>
        </v-flex>
        <v-flex :xs7="moduleObj.enableprofile" :xs9="!moduleObj.enableprofile" :class="moduleObj.enableprofile ? 'pt-1' : 'px-2'" class="pl-3">
          <span class="body-1 font-weight-medium" :style="`color: ${ systemDetails.textcolor }`">
            {{ moduleObj._id ? `${$t('update')} ${$t(`Module_${moduleName}`)}` : `${$t('create')}  ${$t(`Module_${moduleName}`)}` }}
          </span>
        </v-flex>
        <v-flex xs2 class="text-end">
          <v-btn icon small class="mx-1" @click="saveUpdateHandler(moduleObj._id ? 'UPDATE' : 'SAVE')" :disabled="listOfFields.length === 0"
          :color="$vuetify.theme.dark ? '' : systemDetails.textcolor">
            <v-icon size="20"> mdi-checkbox-marked-circle-outline </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-container fluid class="pa-0 mt-2">
      <v-row no-gutters>
        <template v-if="fieldsLoading">
          <v-col v-for="item in 12" :key="item" cols="12" sm="6" lg="3">
            <v-sheet class="mb-1">
              <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </template>
        <v-col cols="12" v-else>
          <v-card color="transparent" flat class="pa-2">
            <module-render
              v-if="!fieldsLoading"
              :listOfFields="listOfFields" :fields="fields" ref="formReferences" :moduleName="moduleName" :key="reRender" :isRecordCreation="!moduleObj._id"
              :eventId="$route.params.record_id || null"
            ></module-render>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="imageDialog" width="300">
      <v-btn icon absolute right class="mt-n9 mr-n1" :color="$vuetify.theme.dark ? '' : 'black'" @click="imageDialog = false;">
        <v-icon size="26"> mdi-close-circle </v-icon>
      </v-btn>
      <v-card class="pa-1" :color="$vuetify.theme.dark ? '' : '#fafafa'">
        <v-img contain :src="moduleObj.profileimage"></v-img>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import mixins from './mixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      moduleName: this.$route.params.name,
      moduleObj: {
        moduleId: this.$route.params.id,
        _id: this.$route.params.record_id
      },
      imageDialog: false,
      fieldsLoading: false,
      listOfFields: [],
      fields: {},
      reRender: 0,
      MODULE_URL: 'moduledata',
      items: [],
      search: '',
      companyArray: [],
      isLoading: false,
      // backUrl: '',
      domain: '',
      imageURL: process.env.VUE_APP_IMAGE_URL,
      reInit: 0
    }
  },
  components: {
    'module-render': () => import('../../components/Modules/ModuleFormRender')
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails']),
    profileMenuList () {
      return [{
        text: this.$t('preview'),
        icon: 'mdi-wallpaper',
        click: () => {
          this.imageDialog = true
        }
      }, {
        text: this.$t('change'),
        icon: 'mdi-camera-retake-outline',
        click: () => this.$refs.file.$refs.input.click(),
        ischange: true
      }, {
        text: this.$t('remove'),
        icon: 'mdi-file-remove-outline',
        click: () => {
          if (this.moduleObj && this.moduleObj._id) {
            this.$root.$emit('customDialog', {
              title: {
                label: this.$t('deleteConfirm'),
                icon: 'mdi-alert-box',
                class: () => 'error white--text'
              },
              body: () => this.$t('areYouSure'),
              actions: [{
                label: this.$t('yes'),
                color: () => 'error',
                click: ({ dialog, button }) => {
                  button.loading = true
                  this.$api.execute('post', `moduledata/${dialog.name}/upload_profile?id=${dialog._id}`, null)
                    .then(() => {
                      this.moduleObj = {
                        ...this.moduleObj,
                        profile: null,
                        profileimage: ''
                      }
                    })
                    .finally(() => {
                      button.loading = false
                      dialog.close()
                    })
                }
              }, {
                label: this.$t('no'),
                color: () => 'primary',
                click: ({ dialog, button }) => {
                  dialog.close()
                }
              }],
              ...this.moduleObj
            })
          } else {
            this.moduleObj = {
              ...this.moduleObj,
              profile: null,
              profileimage: ''
            }
          }
        }
      }]
    }
  },
  watch: {
    'fields.name': function (val) {
      if (val !== undefined) {
        this.autoFillAddress(val)
      }
    },
    'search' (val) {
      if (val === null || val.length <= 1) {
        const model = Object.assign({ ...this.fields }, { city: '', address: '', post: '' })
        this.fields = model
        this.companyArray = []
      } else {
        this.isLoading = true
        this.loadCompanyData()
      }
    },
    '$store.state.common.listOfModules' (val) {
      this.setPermission()
      return ''
    }
  },
  mounted () {
    const moduleObj = this.listOfModules.find((moduleItem) => moduleItem._id === this.moduleObj.moduleId)
    if (moduleObj) this.moduleObj = { ...moduleObj, ...this.moduleObj }
    this.domain = this.userDetails.domain
    // this.backUrl = `/module/${this.moduleName}/${this.moduleObj.moduleId}`
    // if (this.moduleObj._id) this.backUrl += `/view/${this.moduleObj._id}`
    this.isAction = true
    if (this.$route.query && this.moduleName === this.SALE) {
      setTimeout(() => {
        const values = this.$route.query.fromticket ? JSON.parse(decodeURIComponent(this.$route.query.fromticket)) : ''
        const companyIndex = this.listOfFields.findIndex(x => x.name === 'company')
        const contactIndex = this.listOfFields.findIndex(x => x.name === 'contact')
        if (companyIndex >= 0) {
          this.listOfFields[companyIndex].default_value.options = values.companyArray
        }
        if (contactIndex >= 0) {
          this.listOfFields[contactIndex].default_value.options = values.contactArray
        }
        this.fields = { ...this.fields, ...values }
      }, 1000)
    }
    this.getFieldsForModule()
    this.setPermission()
  },
  methods: {
    uploadProfile () {
      if (this.moduleObj.profile) {
        var filesList = this.moduleObj.profile
        var formData = new FormData()
        formData.append(filesList.name, filesList)
        this.$api.execute('post', `moduledata/${this.moduleName}/upload_profile?id=${this.moduleObj._id || ''}`, formData)
          .then(({ data }) => {
            this.moduleObj.profileimage = this.imageURL + this.domain + '/module_profiles/' + data
            this.reInit++
          })
      }
    },
    saveUpdateHandler (type) {
      if (this.moduleObj.profile) this.uploadProfile('event', 'Update')
      this.updateRecordHandler({ id: this.moduleObj._id, moduleName: this.moduleName, moduleId: this.moduleObj.moduleId, type })
    },
    getSingleRecordHandler () {
      this.getRecordHandler(`${this.MODULE_URL}/${this.moduleName}/get_by_id/${this.moduleObj._id}`, '')
    },
    setPermission () {
      if (this.listOfModules.length > 0) {
        const modulename = this.$route.params.name
        const value = this.listOfModules
        const isExists = value.find(x => x.name === modulename)
        if (isExists) {
          const hasManagePermission = this.$formatter.permissionSetting(this.listOfModules, this.moduleObj.moduleId, this.userDetails)
          if (!hasManagePermission) this.$router.push('/dashboard')
          this.showPageLoader = false
        } else {
          this.$router.push('/dashboard')
        }
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('getAPIValues')
    this.$root.$off('getModuleOptions')
    this.$root.$off('rerenderComponent')
  }
}
</script>
